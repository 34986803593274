import Component from '../core/Component'

const STATES = {
    HIDDEN: 'is-hidden',
    IN_FOOTER_COPY: 'is-in-footer-copy',
}

export default class ChatOpener extends Component {
    constructor(element) {
        super(element)

        this.observer = null

        this.hasLoaded = false
        this.state = {
            active: false,
        }

        this.footerCopy = document.querySelector('.Footer-copy')
        this.footerCopyRect = null
        this.window = null
    }

    prepare() {
        document.addEventListener('consentUpdate', this.handleConsentUpdate)

        this.element.addEventListener('click', this.handleClick)

        const fbRoot = document.getElementById('fb-root')

        if (!fbRoot) {
            this.hide()
            return
        }

        this.state.active = true

        setTimeout(() => {
            this.resize()
        }, 1000)

        const config = {
            attributes: false,
            childList: true,
            subtree: true,
        }

        this.observer = new MutationObserver(this.handleMutation)
        this.observer.observe(fbRoot, config)

        window.addEventListener('message', this.handleMessage)
        window.addEventListener('scroll', this.handleScroll)
        window.addEventListener('resize', this.handleResize)

        this.resolveConsent(window.CookieConsent?.settings)
    }

    destroy() {
        document.removeEventListener('consentUpdate', this.handleConsentUpdate)
        window.removeEventListener('message', this.handleMessage)
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('resize', this.handleResize)

        if (this.observer) {
            this.observer.disconnect()
            this.observer = null
        }
    }

    show() {
        this.element.classList.remove(STATES.HIDDEN)
    }

    hide() {
        this.element.classList.add(STATES.HIDDEN)
    }

    handleConsentUpdate = (event) => {
        this.resolveConsent(event.detail)
    }

    handleMessage = (event) => {
        if (this.hasLoaded) {
            return
        }

        if (
            typeof event.data === 'string' &&
            event.data.toLowerCase().indexOf('livechat') > -1 &&
            event.origin === 'https://www.facebook.com'
        ) {
            this.hasLoaded = true
            if ('dataLayer' in window) {
                dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: 'Click',
                    eventAction: 'Messenger',
                    eventLabel: 'ChatLoad',
                })
            }
        }
    }

    handleMutation = (mutationList, observer) => {
        let iframes = []

        for (let mutation of mutationList) {
            iframes = Array.from(mutation.addedNodes).filter(
                (node) => node.tagName.toLowerCase() === 'iframe',
            )

            if (iframes.length) {
                break
            }
        }

        if (iframes.length) {
            iframes[0].addEventListener('load', this.handleChatLoad)
        }
    }

    handleChatLoad = (event) => {
        this.hide()
    }

    handleChatLoadError = (event) => {
        alert(event)
    }

    handleClick = (event) => {
        event.preventDefault()

        window.fbAsyncInit = function () {
            FB.init({
                xfbml: true,
                version: 'v8.0',
            })
        }

        const fjs = document.getElementsByTagName('script')[0]

        if (document.getElementById('facebook-jssdk')) {
            return
        }

        if ('dataLayer' in window) {
            dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'Click',
                eventAction: 'Messenger',
                eventLabel: 'StartChat',
            })
        }

        const js = document.createElement('script')
        js.id = 'facebook-jssdk'
        js.async = true
        js.src = 'https://connect.facebook.net/cs_CZ/sdk/xfbml.customerchat.js'
        fjs.parentNode.insertBefore(js, fjs)
    }

    handleResize = () => {
        this.resize()
    }

    handleScroll = (event) => {
        if (!this.footerCopyRect) {
            return
        }

        if (this.footerCopyRect.documentY < this.getScroll().y + this.window.y) {
            this.element.classList.add(STATES.IN_FOOTER_COPY)
        } else {
            this.element.classList.remove(STATES.IN_FOOTER_COPY)
        }
    }

    getScroll() {
        return {
            x: document.body.scrollLeft || document.documentElement.scrollLeft || 0,
            y: document.body.scrollTop || document.documentElement.scrollTop || 0,
        }
    }

    resize() {
        this.window = {
            y: window.innerHeight,
        }

        if (!this.footerCopy) {
            return
        }

        const footerCopyRect = this.footerCopy.getBoundingClientRect()
        this.footerCopyRect = {
            documentY: this.getScroll().y + footerCopyRect.top,
        }
    }

    resolveConsent(settings) {
        const { ads } = settings || {}

        if (ads && this.state.active) {
            this.show()
        }
    }
}
