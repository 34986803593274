// general
import Antispam from '../../components/Antispam'
import CookieBubble from '../../components/CookieBubble'

// project
import Calendar from './Calendar'
import ChatOpener from './ChatOpener'
import Nav from './Nav'
import Menu from './Menu'
import Order from './Order'
import OrderOpener from './OrderOpener'
import NavOpener from './NavOpener'
import AboutSlider from './AboutSlider'
import FoodSlider from './FoodSlider'
import FormOffice from './FormOffice'
import HowSlider from './HowSlider'
import WhySlider from './WhySlider'
import Switch from './Switch'
import ImageCompare from './ImageCompare'
import GoogleMap from './GoogleMap'
import ContactMap from './ContactMap'
import ViewMore from './ViewMore'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import Tabs from './Tabs'


const Components = {
    Nav,
    NavOpener,
    Menu,
    AboutSlider,
    FoodSlider,
    FormOffice,
    HowSlider,
    WhySlider,
    Switch,
    Order,
    OrderOpener,
    Calendar,
    ChatOpener,
    ImageCompare,
    GoogleMap,
    ContactMap,
    Antispam,
    ViewMore,
    Modal,
    ModalOpener,
    Tabs
}

export default Components
